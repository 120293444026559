import { styled } from '../stitches'
import { gap } from '../variants'
import { Box } from './box'

export interface StackProps extends React.ComponentProps<typeof VStack> {
  as?: React.ElementType
}

const BASE_STYLES = {
  display: 'grid',
  width: '100%',
  minWidth: 0,
  variants: { ...gap },
}

export const VStack = styled(Box, {
  ...BASE_STYLES,
  gridAutoFlow: 'row',
  gridAutoColumns: 'minmax(0, 1fr)',
})

VStack.displayName = 'VStack'

export const HStack = styled(Box, {
  ...BASE_STYLES,
  gridAutoFlow: 'column',
  gridAutoColumns: 'minmax(min-content, max-content)',
})

HStack.displayName = 'HStack'

export const ZStack = styled(Box, {
  ...BASE_STYLES,
  gridTemplate: `'salad-z-stack-item' 1fr / 1fr`,
  '&::before, &::after, & > *': { gridArea: 'salad-z-stack-item' },
})

ZStack.displayName = 'ZStack'
