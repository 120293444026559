import { useCardChallengeChannel } from 'domains/payment/queries'
import type { CardChallengeStatus } from 'domains/payment/types'
import { useParams } from 'kitchen/router'
import { useEffect } from 'react'
import { useIntercom } from 'react-use-intercom'
import { Status } from 'salad/components'
import { StatusLayout } from 'salad/layouts'
import { Box } from 'salad/primitives'
import { theme } from 'salad/stitches'
import { LoadingStatus } from '../../components'

export function CardChallengeStatusPage() {
  const { status } = useParams<{ status: CardChallengeStatus }>()
  const { shutdown } = useIntercom()
  const cardChallengeChannel = useCardChallengeChannel()

  useEffect(() => {
    shutdown()
  }, [shutdown])

  useEffect(() => {
    cardChallengeChannel.post(status)
  }, [cardChallengeChannel, status])

  switch (status) {
    case 'success':
    case 'failure':
      return (
        <Box css={{ height: '100%', backgroundColor: theme.colors['white'] }}>
          <LoadingStatus />
        </Box>
      )
    default:
      return (
        <StatusLayout.Root>
          <StatusLayout.Content>
            <Status.Root>
              <Status.Title>Page not found</Status.Title>
            </Status.Root>
          </StatusLayout.Content>
        </StatusLayout.Root>
      )
  }
}
