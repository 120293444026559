import { z } from 'zod'
import { MoneySchema } from '../money/schemas'

export const PayrunIdSchema = z.string().uuid().brand('PayrunId')

export const PayrunStateSchema = z.enum([
  'CREATED',
  'PENDING',
  'PAYING',
  'PAID',
  'DECLINED',
  'EXPORTED',
])

export const GetPaidPayrunSchema = z.object({
  state: PayrunStateSchema,
  id: PayrunIdSchema,
  amount: MoneySchema,
  fee: MoneySchema,
  reference: z.string(),
  paidAt: z.string().datetime().nullable(),
})
