import type { AccountType } from 'api/types/accounts'
import { useIntl } from 'react-intl'
import { match } from 'ts-pattern'

export function useAccountTypeLabel(type: AccountType | null) {
  const { formatMessage } = useIntl()

  return match(type)
    .with(null, () => null)
    .with('BANK', () =>
      formatMessage({
        id: 'account.account-type.bank',
        defaultMessage: 'Bank',
      })
    )
    .with('CARD', () =>
      formatMessage({
        id: 'account.account-type.card',
        defaultMessage: 'Card',
      })
    )
    .with('WALLET', () =>
      formatMessage({
        id: 'account.account-type.wallet',
        defaultMessage: 'Wallet',
      })
    )
    .exhaustive()
}
