import type { BeneficiaryId } from 'api/types/beneficiaries'
import type { CompanyId } from 'api/types/companies'
import type { UserId } from 'api/types/users'
import type { InvoiceId } from 'domains/invoice/types'
import { useCallback } from 'react'
import type { PayrunId } from '../../payrun/types'
import { useSendAnalyticsEvent } from '../queries'
import type { UIInteractionData } from '../types'

export function useSendInteraction(
  companyId: CompanyId | undefined,
  subjectId?: InvoiceId | PayrunId | BeneficiaryId | UserId
) {
  const sendAnalyticsEvent = useSendAnalyticsEvent().mutate

  return useCallback(
    (data: UIInteractionData) =>
      sendAnalyticsEvent({
        event: 'UI_INTERACTION',
        data,
        companyId,
        subjectId,
      }),
    [sendAnalyticsEvent, companyId, subjectId]
  )
}
