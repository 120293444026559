import { Chip } from 'salad/components'
import { ButtonBase, Text } from 'salad/primitives'
import { styled, theme } from 'salad/stitches'
import { space } from 'salad/tokens'
import { typography } from 'salad/variants'

const PaymentMethodBaseStart = styled('span', {
  display: 'grid',
  placeItems: 'center',
  gridArea: 'start',
  borderRadius: theme.radii[4],
  overflow: 'hidden',
  size: 32,
  ':disabled &, [aria-disabled="true"] &': { opacity: 0.3 },
})

PaymentMethodBaseStart.displayName = 'PaymentMethod.Start'

const PaymentMethodBaseTitle = styled(Text, {
  gridArea: 'title',
  color: theme.colors['black'],
  ':disabled &, [aria-disabled="true"] &': { color: theme.colors['black-alpha-30'] },
})

PaymentMethodBaseTitle.displayName = 'PaymentMethod.Title'

const PaymentMethodBaseDescription = styled(Text, {
  gridArea: 'description',
  color: theme.colors['grey-60'],
  ':where(button:active, [aria-pressed="true"], [aria-selected="true"]) &': {
    color: theme.colors['black-alpha-40'],
  },
  ':disabled &, [aria-disabled="true"] &': {
    color: theme.colors['black-alpha-20'],
  },
})

PaymentMethodBaseDescription.displayName = 'PaymentMethod.Description'

const PaymentMethodBaseStatus = styled(Chip, {
  gridArea: 'status',
  marginTop: space[4],
})

PaymentMethodBaseStatus.displayName = 'PaymentMethod.Status'

const PaymentMethodBaseEnd = styled('span', {
  display: 'grid',
  gridArea: 'end',
  gridAutoFlow: 'column',
  color: theme.colors['black'],
  ':disabled &, [aria-disabled="true"] &': { color: theme.colors['black-alpha-20'] },
})

PaymentMethodBaseEnd.displayName = 'PaymentMethod.End'

interface PaymentMethodBaseProps extends React.ComponentProps<typeof PaymentMethodBase> {}

const PaymentMethodBase = styled(ButtonBase, {
  display: 'grid',
  gridTemplate: `
    'start title       end' minmax(0, 1fr)
    'start description end' minmax(0, auto)
    '.     status      end' minmax(0, auto) / auto 1fr auto
  `,
  width: '100%',
  alignItems: 'center',
  variants: {
    surface: {
      none: {},
      card: {
        minHeight: 80,
        borderRadius: theme.space[16],
        backgroundColor: theme.colors['white'],
        boxShadow: theme.shadows['elevation-100'],
        paddingBlock: theme.space[16],
        paddingInline: theme.space[24],
        transition: 'box-shadow 0.3s',
        '&:hover:is(button):not(:disabled)': {
          boxShadow: theme.shadows['elevation-200'],
        },
        '&:active:is(button), &[aria-pressed="true"]': {
          boxShadow: theme.shadows['elevation-300'],
          '&:hover': { boxShadow: theme.shadows['elevation-400'] },
        },
      },
      option: {
        borderRadius: theme.space[8],
        padding: theme.space[8],
        transition: 'background-color 0.3s',
        '&:hover:is(button):not(:disabled):not([aria-pressed="true"])': {
          backgroundColor: theme.colors['grey-5'],
        },
        '&:active:is(button), &[aria-pressed="true"]': {
          backgroundColor: theme.colors['grey-10'],
        },
        '&[aria-selected="true"]': {
          backgroundColor: theme.colors['light-blue-80'],
          '&:hover:not(:disabled)': { backgroundColor: theme.colors['light-blue'] },
        },
      },
    },
    size: {
      medium: {
        [`${PaymentMethodBaseStart}`]: {
          marginInlineEnd: theme.space[12],
        },
        [`${PaymentMethodBaseEnd}`]: {
          gap: theme.space[12],
          marginInlineStart: theme.space[12],
        },
        [`${PaymentMethodBaseTitle}`]: {
          ...typography['title-16'],
        },
        [`${PaymentMethodBaseDescription}`]: {
          ...typography['paragraph-16'],
        },
      },
      small: {
        [`${PaymentMethodBaseStart}`]: {
          marginInlineEnd: theme.space[8],
        },
        [`${PaymentMethodBaseEnd}`]: {
          gap: theme.space[8],
          marginInlineStart: theme.space[8],
        },
        [`${PaymentMethodBaseTitle}`]: {
          ...typography['title-14'],
        },
        [`${PaymentMethodBaseDescription}`]: {
          ...typography['paragraph-14'],
        },
      },
    },
  },
  defaultVariants: {
    surface: 'none',
    size: 'medium',
  },
})

PaymentMethodBase.displayName = 'PaymentMethod.Root'

export {
  PaymentMethodBase as Root,
  type PaymentMethodBaseProps as RootProps,
  PaymentMethodBaseStart as Start,
  PaymentMethodBaseTitle as Title,
  PaymentMethodBaseDescription as Description,
  PaymentMethodBaseEnd as End,
  PaymentMethodBaseStatus as Status,
}
