import type React from 'react'
import { styled } from '../stitches'
import { gap } from '../variants'
import { Box } from './box'

export interface GridProps extends React.ComponentProps<typeof Grid> {}

export const Grid = styled(Box, {
  display: 'grid',
  variants: { ...gap },
})

Grid.displayName = 'Grid'
