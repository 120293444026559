import { Flex } from '../../primitives'
import { styled } from '../../stitches'

export const ButtonGroup = styled(Flex, {
  '> :not(:first-child)': {
    borderStartStartRadius: 0,
    borderEndStartRadius: 0,
  },
  '> :not(:last-child)': {
    borderStartEndRadius: 0,
    borderEndEndRadius: 0,
  },
})
