import { useIsStuck } from 'kitchen/hooks/use-is-stuck'
import { useRef } from 'react'
import { Button } from '../../components'
import { Box, Grid, type GridProps, VStack } from '../../primitives'
import { styled, theme } from '../../stitches'

export interface StepLayoutProps extends React.ComponentProps<typeof StepLayout> {}

const StepLayout = styled(Grid, {
  gridAutoFlow: 'row',
  gridAutoColumns: '1fr',
  rowGap: theme.space[32],
  alignContent: 'start',
  marginBlockEnd: theme.space[32],
  minHeight: '100%',
  marginInline: 'auto',
  paddingBlock: theme.space[20],
  paddingInline: theme.space[8],
  '@bp1': {
    paddingBlock: theme.space[32],
  },
  variants: {
    size: {
      384: {
        maxWidth: 384,
        '@bp1': { paddingInline: theme.space[0] },
      },
      448: {
        maxWidth: 448,
        '@bp1': { paddingInline: theme.space[0] },
      },
      512: {
        maxWidth: 512,
        '@bp1': { paddingInline: theme.space[0] },
      },
      600: {
        maxWidth: 600,
        '@bp1': { paddingInline: theme.space[0] },
      },
      684: {
        maxWidth: 684,
        '@bp1': { paddingInline: theme.space[0] },
      },
      800: {
        maxWidth: 800,
        justifyContent: 'center',
        '@bp3': { paddingInline: theme.space[0] },
      },
      945: {
        maxWidth: 945,
        justifyContent: 'center',
        '@bp3': { paddingInline: theme.space[0] },
      },
      992: {
        maxWidth: 992,
        justifyContent: 'center',
        '@bp3': { paddingInline: theme.space[0] },
      },
      1096: {
        maxWidth: 1096,
        justifyContent: 'center',
        '@bp3': { paddingInline: theme.space[0] },
      },
      1168: {
        maxWidth: 1168,
        justifyContent: 'center',
        '@bp3': { paddingInline: theme.space[0] },
      },
      1296: {
        maxWidth: 1296,
        justifyContent: 'center',
        '@bp3': { paddingInline: theme.space[0] },
      },
    },
  },
  defaultVariants: { size: 448 },
})

export interface StepLayoutHeaderProps extends GridProps {}

const StepLayoutHeader = ({ css, ...rest }: StepLayoutHeaderProps) => (
  <Grid
    css={{
      width: '100%',
      maxWidth: 448,
      justifySelf: 'center',
      alignContent: 'center',
      justifyContent: 'stretch',
      '@bp1': { width: 'auto', justifyContent: 'center' },
      ...css,
    }}
    {...rest}
  />
)

export interface StepLayoutActionProps extends Button.ButtonProps {
  sticky?: boolean
}

function StepLayoutAction({ children, css, sticky, ...rest }: StepLayoutActionProps) {
  const ref = useRef<HTMLDivElement | null>(null)
  const isStuck = useIsStuck(ref, sticky ? 'bottom' : 'none')

  return (
    <VStack
      ref={ref}
      gap="16"
      css={{
        maxWidth: 448,
        justifySelf: 'center',
        '&[data-sticky]': {
          position: 'sticky',
          bottom: 0,
          paddingBlockEnd: theme.space[24],
        },
        ...css,
      }}
      data-sticky={sticky}
    >
      <Box px={32}>
        <Button.Root variant="main" size="large" elevated={isStuck} {...rest}>
          {children}
        </Button.Root>
      </Box>
    </VStack>
  )
}

export { StepLayout as Root, StepLayoutHeader as Header, StepLayoutAction as Action }
