import { MoneySchema } from './schemas'
import type { Money, CurrencyCode } from './types'

type ValidationMessage = string | ((value: Money) => string)

const getValidationMessage = (value: Money, messageOrGetter: ValidationMessage) =>
  typeof messageOrGetter === 'function' ? messageOrGetter(value) : messageOrGetter

export const money = () => MoneySchema

export const currency = (code: CurrencyCode, message: ValidationMessage) =>
  MoneySchema.refine(
    (value) => value.currency === code,
    (value) => ({
      message: getValidationMessage(value, message),
    })
  )

export const min = (amount: number, message: string) =>
  MoneySchema.refine(
    (value) => value.amount >= amount,
    (value) => ({
      message: getValidationMessage(value, message),
    })
  )

export const max = (amount: number, message: string) =>
  MoneySchema.refine(
    (value) => value.amount <= amount,
    (value) => ({
      message: getValidationMessage(value, message),
    })
  )

export const required = (message: string) =>
  MoneySchema.refine(
    (value) => value.amount > 0,
    (value) => ({
      message: getValidationMessage(value, message),
    })
  )
