import type { ReactNode } from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'
import { match } from 'ts-pattern'
import type { AppLocale, IntlMessagesResponse, WebsiteLocale } from '../../types'
import { initIntl } from '../../utils/intl'
import { IntlMessageLoader } from '../intl-message-loader'

interface IntlProviderPropsBase {
  children: ReactNode
}

interface AppIntlProviderProps extends IntlProviderPropsBase {
  packageName: 'app'
  locale: AppLocale
}

interface WebsiteIntlProviderProps extends IntlProviderPropsBase {
  packageName: 'website'
  locale: WebsiteLocale
  messages: IntlMessagesResponse
}

interface StorybookIntlProviderProps extends IntlProviderPropsBase {
  packageName: 'cookbook'
  locale?: string
}

interface BOIntlProviderProps extends IntlProviderPropsBase {
  packageName: 'backoffice'
}

export type IntlProviderProps =
  | AppIntlProviderProps
  | WebsiteIntlProviderProps
  | StorybookIntlProviderProps
  | BOIntlProviderProps

export const IntlProvider = ({ children, ...data }: IntlProviderProps) => {
  return (
    <>
      {match(data)
        .with({ packageName: 'app' }, ({ locale }) => (
          <IntlMessageLoader locale={locale} packageName="app">
            {(messages) => {
              initIntl(locale, messages)
              return (
                <ReactIntlProvider locale={locale} messages={messages}>
                  {children}
                </ReactIntlProvider>
              )
            }}
          </IntlMessageLoader>
        ))
        .with({ packageName: 'website' }, ({ locale, messages }) => {
          initIntl(locale, messages)
          return (
            <ReactIntlProvider locale={locale} messages={messages}>
              {children}
            </ReactIntlProvider>
          )
        })
        .with({ packageName: 'backoffice' }, () => {
          initIntl('en-GB', {})
          return <ReactIntlProvider locale="en-GB">{children}</ReactIntlProvider>
        })
        .with({ packageName: 'cookbook' }, ({ locale = 'en-GB' }) => {
          initIntl(locale, {})
          return <ReactIntlProvider locale={locale}>{children}</ReactIntlProvider>
        })
        .exhaustive()}
    </>
  )
}
