import * as currencies from '@dinero.js/currencies'
import {
  type Dinero,
  dinero,
  allocate,
  toDecimal,
  add,
  multiply,
  transformScale,
  halfEven,
} from 'dinero.js'
import { unique } from 'kitchen/utils/helpers'
import { CurrencyCodeSchema } from './schemas'
import type { Money, ScaledMoney, CurrencyCode } from './types'

export const toDinero = (input: ScaledMoney): Dinero<number> =>
  dinero({
    amount: input.amount,
    currency: currencies[input.currency],
    scale: input.scale,
  })

interface MoneyOptions {
  preserveScale?: boolean
}

export const toMoney = (
  input: Dinero<number>,
  { preserveScale }: MoneyOptions = {}
): ScaledMoney => {
  const { amount, currency, scale } = input.toJSON()

  if (preserveScale) {
    return {
      amount,
      currency: CurrencyCodeSchema.parse(currency.code),
      scale,
    }
  }

  const snapshot = transformScale(input, currency.exponent, halfEven).toJSON()

  return {
    amount: snapshot.amount,
    currency: CurrencyCodeSchema.parse(snapshot.currency.code),
  }
}

export const toFloat = (input: Money) =>
  toDecimal(toDinero(input), ({ value }) => Number.parseFloat(value))

export const moneyFromFloat = (input: {
  float: number
  currency: CurrencyCode
  scale?: number
}): ScaledMoney => {
  const currency = currencies[input.currency]

  if (typeof currency === 'undefined') {
    throw new Error(`Currency code not found: ${input.currency}`)
  }

  if (typeof currency.base !== 'number') {
    throw new TypeError('Currency base is not a number')
  }

  const [whole, fraction = ''] = input.float
    .toLocaleString('en-US', {
      useGrouping: false,
      maximumFractionDigits: 20,
    })
    .split('.')

  const amount = Math.abs(Number.parseInt(whole + fraction))

  if (Number.isNaN(amount)) {
    throw new Error(`Not a number: ${amount}`)
  }

  const result = multiply(
    transformScale(
      dinero({ amount, currency, scale: fraction.length }),
      input.scale ?? currency.exponent
    ),
    Math.sign(input.float)
  )

  return toMoney(result, {
    preserveScale: input.scale !== undefined,
  })
}

export const getFee = (input: ScaledMoney, fee: number, options?: MoneyOptions) =>
  toMoney(
    allocate(transformScale(toDinero(input), input.scale ?? 5, halfEven), [
      fee,
      1 - fee,
    ])[0],
    options
  )

interface TotalOptions extends MoneyOptions {
  transform?: (money: Dinero<number>, source: ScaledMoney) => Dinero<number>
}

export const getTotal = (
  input: ScaledMoney[],
  currency: CurrencyCode,
  { transform = (input) => input, ...options }: TotalOptions = {}
): Money =>
  toMoney(
    input
      .filter((item) => item.amount !== 0)
      .map((item) => transform(toDinero(item), item))
      .reduce((a, b) => add(a, b), toDinero({ amount: 0, currency })),
    options
  )

export const getCurrencies = (input: Money[]) =>
  unique(input.map((item) => item.currency))
