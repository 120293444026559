import { useSendInteraction, useTrackUIView } from 'domains/analytics/hooks'
import { getIntl } from 'domains/i18n/utils'
import { MoneyInput } from 'domains/money/components'
import { LOCAL_CURRENCY } from 'domains/payment/constants'
import { Controller, useForm, zodResolver } from 'kitchen/forms'
import { parseAsInteger, parseAsString, useQueryState } from 'kitchen/router'
import * as formats from 'kitchen/utils/formats'
import { useId } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  InputGroup,
  Surface,
  Item,
  Avatar,
  Separator,
  Button,
  PayeeReferenceInput,
  PaymentNetworkIcon,
} from 'salad/components'
import { StepLayout } from 'salad/layouts'
import { Box, Flex, Text, VStack, HStack } from 'salad/primitives'
import { theme } from 'salad/stitches'
import { ApronFooter } from '../../../components'
import { EntryStepSchema } from '../schemas'
import type { BeneficiaryCompany, EntryStepValues } from '../types'

interface EntryStepProps {
  beneficiaryCompany: BeneficiaryCompany
  onSubmit: (values: EntryStepValues) => void
  submitting: boolean
}

export function EntryStep({ beneficiaryCompany, onSubmit, submitting }: EntryStepProps) {
  const [amount, setUrlAmount] = useQueryState('amount', parseAsInteger.withDefault(0))
  const [billReference, setUrlBillReference] = useQueryState(
    'reference',
    parseAsString.withDefault('')
  )

  const intl = getIntl()
  const formId = useId()
  const sendInteraction = useSendInteraction(undefined)

  const { handleSubmit, control } = useForm({
    defaultValues: {
      amount: { amount, currency: LOCAL_CURRENCY },
      billReference: formats.payeeReference(billReference, LOCAL_CURRENCY),
      beneficiaryCompany,
    },
    resolver: zodResolver(EntryStepSchema),
  })

  useTrackUIView({
    companyId: undefined,
    data: {
      target: 'get-paid-flow',
      step: 'entry',
    },
  })

  const amountLabel = intl.formatMessage({
    id: 'get-paid.payment.entry-step.input-label.amount',
    defaultMessage: 'Amount',
  })

  return (
    <StepLayout.Root size={512} css={{ gridTemplateRows: 'auto 1fr' }}>
      <VStack gap="32" as="form" id={formId} onSubmit={handleSubmit(onSubmit)}>
        <Surface variant="flat">
          <VStack>
            <Box p={24}>
              <Item.Root size="medium" css={{ minHeight: 62, pointerEvents: 'none' }}>
                <Item.Start>
                  <Avatar
                    variant="square"
                    name={beneficiaryCompany.legalName}
                    size="large"
                    aria-hidden
                  />
                </Item.Start>
                <Item.Content>
                  <VStack>
                    <FormattedMessage
                      id="get-paid.payment.entry-step.title"
                      defaultMessage="<h2>{companyName}</h2><p>is requesting a payment</p>"
                      values={{
                        companyName: beneficiaryCompany.legalName,
                        h2: (text) => <Text variant="headline-h2">{text}</Text>,
                        p: (text) => (
                          <Text variant="paragraph-16" color="black-alpha-60">
                            {text}
                          </Text>
                        ),
                      }}
                    />
                  </VStack>
                </Item.Content>
              </Item.Root>
            </Box>
            <Separator size={1} />
            <VStack p={32} gap={24}>
              <Controller
                control={control}
                name="amount"
                render={({ field, fieldState: { error } }) => (
                  <InputGroup.Root>
                    <InputGroup.Label>{amountLabel}</InputGroup.Label>
                    <MoneyInput
                      aria-label={amountLabel}
                      aria-invalid={error !== undefined}
                      placeholder={field.value}
                      autoComplete="off"
                      value={field.value}
                      onValueChange={(value) => {
                        setUrlAmount(value.amount)
                        field.onChange(value)
                      }}
                    />
                    {error && <InputGroup.Message>{error.message}</InputGroup.Message>}
                  </InputGroup.Root>
                )}
              />

              <Controller
                control={control}
                name="billReference"
                render={({ field, fieldState: { error } }) => (
                  <InputGroup.Root>
                    <InputGroup.Label>
                      <FormattedMessage
                        id="get-paid.payment.entry-step.input-label.reference"
                        defaultMessage="Invoice reference"
                      />
                    </InputGroup.Label>
                    <PayeeReferenceInput
                      {...field}
                      onChange={(value) => {
                        setUrlBillReference(value)
                        field.onChange(value)
                      }}
                      currency={LOCAL_CURRENCY}
                      autoComplete="off"
                      aria-invalid={error !== undefined}
                    />
                    {error && <InputGroup.Message>{error.message}</InputGroup.Message>}
                  </InputGroup.Root>
                )}
              />
            </VStack>
            <Separator size={1} />
            <Flex p={32} align="center" direction="column" gap={8}>
              <Button.Root
                form={formId}
                variant="main"
                size="large"
                type="submit"
                onClick={() => {
                  sendInteraction({
                    type: 'click',
                    target: 'get-paid-pay-invoice-button',
                  })
                }}
                loading={submitting}
              >
                <Button.Content>
                  <FormattedMessage
                    id="get-paid.payment.entry-step.submit"
                    defaultMessage="Pay invoice"
                  />
                </Button.Content>
              </Button.Root>
              <HStack gap={6} css={{ justifyContent: 'center', alignItems: 'center' }}>
                <PaymentNetworkIcon scheme="VISA" variant="color" />
                <PaymentNetworkIcon scheme="MASTER_CARD" variant="color" />
                <PaymentNetworkIcon scheme="AMEX" variant="color" />
                <Text
                  css={{
                    fontFamily: theme.fonts.dmsans,
                    fontWeight: theme.fontWeights[700],
                    fontSize: '12px',
                    lineHeight: '20px',
                  }}
                >
                  <FormattedMessage
                    id="account.payment-scheme.bank-transfer"
                    defaultMessage="Bank transfer"
                  />
                </Text>
              </HStack>
            </Flex>
          </VStack>
        </Surface>
      </VStack>
      <ApronFooter />
    </StepLayout.Root>
  )
}
