import type { CardScheme } from 'api/types/cards'
import { FramesCheckout } from 'domains/account/components'
import { useTokenizeCard } from 'domains/account/hooks'
import { toCardScheme } from 'domains/account/utils'
import { useTrackUIView } from 'domains/analytics/hooks'
import { useGetPaidPayrun, useUpdateGetPaidPayrunAuthor } from 'domains/payrun/queries'
import type { GetPaidPayrun } from 'domains/payrun/types'
import {
  Frames,
  type FramesElementsValidity,
  type FrameElementIdentifer,
} from 'frames-react'
import { StorageKey } from 'kitchen/constants'
import { useForm, Controller, zodResolver } from 'kitchen/forms'
import type { UUID } from 'kitchen/types'
import { assert, createUUID } from 'kitchen/utils/helpers'
import { useId, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  InputGroup,
  Input,
  PaymentNetworkIcon,
  Toast,
  StepHeader,
  Surface,
  Button,
} from 'salad/components'
import { StepLayout } from 'salad/layouts'
import { HStack, VStack } from 'salad/primitives'
import { z } from 'zod'
import { PayStepSchema } from '../schemas'
import type { PayStepValues } from '../types'
import { usePay } from '../use-pay'

const FIELD_NAME_MAP: Record<FrameElementIdentifer, keyof FramesElementsValidity> = {
  'card-number': 'cardNumber',
  'expiry-date': 'expiryDate',
  cvv: 'cvv',
}

interface Values {
  payrun: GetPaidPayrun
  email: string
  elements: Record<keyof FramesElementsValidity, boolean | null>
}

const truthy = z
  .boolean()
  .nullable()
  .refine((val) => val === true, {
    message: 'Incorrect value',
  })

const validationSchema = PayStepSchema.extend({
  elements: z.object({
    cardNumber: truthy,
    expiryDate: truthy,
    cvv: truthy,
  }),
})

interface PayStepProps {
  values: PayStepValues
  onContinue: (values: PayStepValues) => void
  onBack: () => void
}

export function PayByCardStep({ values, onContinue, onBack }: PayStepProps) {
  const formId = useId()

  const getPaidPayrun = useGetPaidPayrun({ payrunId: values.payrun.id })
  const toast = Toast.useContext()
  const [cardScheme, setCardScheme] = useState<CardScheme>('UNKNOWN')
  const [pay, paymentElement] = usePay({
    onSuccess: async () => {
      const updatedPayrun = await getPaidPayrun.refetch()
      assert(updatedPayrun.data, 'Missing payrun')

      onContinue({
        ...values,
        payrun: updatedPayrun.data,
      })
    },
  })

  useTrackUIView({
    companyId: undefined,
    data: {
      target: 'get-paid-flow',
      step: 'pay',
    },
  })

  const [idempotencyKey] = useState(() => createUUID<UUID>())

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<Values>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      ...values,
      elements: { cardNumber: null, expiryDate: null, cvv: null },
    },
  })

  const updateGetPaidPayrunAuthor = useUpdateGetPaidPayrunAuthor({
    onError: () => {
      return toast.show(
        <Toast.Root variant="error">
          <Toast.Title>
            <FormattedMessage
              id="get-paid.payment.update-author.error-toast"
              defaultMessage="Failed to update email address. Please try again later."
            />
          </Toast.Title>
        </Toast.Root>
      )
    },
  })

  const tokenizeCard = useTokenizeCard({
    onError: () => {
      Frames.enableSubmitForm()

      return toast.show(
        <Toast.Root variant="error">
          <Toast.Title>Something went wrong. Please try again later.</Toast.Title>
        </Toast.Root>
      )
    },
  })

  function onError() {
    toast.show(
      <Toast.Root variant="error">
        <Toast.Title>
          Something went wrong. Please try to reload the page and try again
        </Toast.Title>
      </Toast.Root>
    )
  }

  async function handleFormSubmit(values: Values) {
    localStorage.setItem(StorageKey.GET_PAID_PAYER_EMAIL, values.email)

    await updateGetPaidPayrunAuthor.mutateAsync({
      payrunId: values.payrun.id,
      email: values.email,
    })

    const tokenized = await tokenizeCard.mutateAsync({ validate: () => 'OK' })

    pay.mutate({
      payrunId: values.payrun.id,
      token: tokenized.token,
      accountType: 'CARD',
      idempotencyKey,
    })
  }

  return (
    <StepLayout.Root>
      <StepLayout.Header>
        <StepHeader.Root onBack={onBack}>
          <StepHeader.Title>
            <FormattedMessage
              id="get-paid.payment.pay-by-card-step.title"
              defaultMessage="Provide your card details and email"
            />
          </StepHeader.Title>
          <StepHeader.Subtitle>
            <FormattedMessage
              id="get-paid.payment.pay-by-card-step.subtitle"
              defaultMessage="We’ll use email to send you a receipt."
            />
          </StepHeader.Subtitle>
        </StepHeader.Root>
      </StepLayout.Header>
      <Surface
        id={formId}
        p="32"
        variant="flat"
        as="form"
        onSubmit={handleSubmit(handleFormSubmit)}
        onReset={() => {
          tokenizeCard.reset()
        }}
      >
        <VStack gap={24}>
          <InputGroup.Root>
            <InputGroup.Label>
              <FormattedMessage
                id="get-paid.payment.pay-by-card-step.input-label.email"
                defaultMessage="Email"
              />
            </InputGroup.Label>
            <Input
              placeholder="example@email.com"
              {...register('email')}
              inputMode="email"
              autoComplete="email"
              autoCapitalize="none"
              aria-invalid={errors.email !== undefined}
            />
            <InputGroup.Message>{errors.email?.message}</InputGroup.Message>
          </InputGroup.Root>
          <Controller
            name="elements"
            control={control}
            render={({ field }) => (
              <FramesCheckout.Root
                onError={onError}
                frameBlur={() => field.onBlur()}
                cardValidationChanged={(event) => {
                  if (event.isValid) {
                    field.onChange(event.isElementValid)
                  }
                }}
                frameValidationChanged={(event) =>
                  field.onChange({
                    ...field.value,
                    [FIELD_NAME_MAP[event.element]]: event.isEmpty ? null : event.isValid,
                  })
                }
                cardBinChanged={(event) => setCardScheme(toCardScheme(event.scheme))}
              >
                <InputGroup.Root>
                  <InputGroup.Label>
                    <FormattedMessage
                      id="get-paid.payment.pay-by-card-step.input-label.card-number"
                      defaultMessage="Card number"
                    />
                  </InputGroup.Label>
                  <InputGroup.Content
                    aria-invalid={errors.elements?.cardNumber?.message !== undefined}
                  >
                    <FramesCheckout.CardNumber />
                  </InputGroup.Content>
                  <InputGroup.End>
                    <PaymentNetworkIcon scheme={cardScheme} />
                  </InputGroup.End>
                  <InputGroup.Message>
                    {errors.elements?.cardNumber?.message}
                  </InputGroup.Message>
                </InputGroup.Root>
                <HStack gap={16} css={{ gridAutoColumns: '1fr' }}>
                  <InputGroup.Root>
                    <InputGroup.Label>
                      <FormattedMessage
                        id="get-paid.payment.pay-by-card-step.input-label.expiry-date"
                        defaultMessage="Expiry date"
                      />
                    </InputGroup.Label>
                    <InputGroup.Content
                      aria-invalid={errors.elements?.expiryDate?.message !== undefined}
                    >
                      <FramesCheckout.ExpiryDate />
                    </InputGroup.Content>
                    <InputGroup.Message>
                      {errors.elements?.expiryDate?.message}
                    </InputGroup.Message>
                  </InputGroup.Root>
                  <InputGroup.Root>
                    <InputGroup.Label>
                      <FormattedMessage
                        id="get-paid.payment.pay-by-card-step.input-label.cvv"
                        defaultMessage="Security code"
                      />
                    </InputGroup.Label>
                    <InputGroup.Content
                      aria-invalid={errors.elements?.cvv?.message !== undefined}
                    >
                      <FramesCheckout.Cvv />
                    </InputGroup.Content>
                    <InputGroup.Message>
                      {errors.elements?.cvv?.message}
                    </InputGroup.Message>
                  </InputGroup.Root>
                </HStack>
              </FramesCheckout.Root>
            )}
          />
        </VStack>
      </Surface>
      <StepLayout.Action
        type="submit"
        form={formId}
        loading={tokenizeCard.isLoading || pay.isLoading}
      >
        <Button.Content>
          <FormattedMessage
            id="get-paid.payment.pay-by-card-step.submit"
            defaultMessage="Pay"
          />
        </Button.Content>
      </StepLayout.Action>
      {paymentElement}
    </StepLayout.Root>
  )
}
