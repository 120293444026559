import { styled, theme } from '../../stitches'
import { typography } from '../../variants'

export interface ChipProps extends React.ComponentProps<typeof Chip> {}

export const Chip = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  gridAutoColumns: 'auto auto',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.space[4],
  width: 'max-content',
  whiteSpace: 'nowrap',
  minHeight: 24,
  '& > svg': {
    marginInlineStart: `calc(${theme.space[4]} * -1)`,
  },
  variants: {
    size: {
      small: {
        paddingInline: theme.space[8],
        paddingBlock: theme.space[2],
        borderRadius: theme.radii[4],
        ...typography['paragraph-14'],
        textTransform: 'capitalize',
      },
      medium: {
        paddingInline: theme.space[12],
        paddingBlock: theme.space[4],
        borderRadius: theme.radii['round'],
        ...typography['label-13'],
        textTransform: 'uppercase',
      },
    },
    variant: {
      positive: {
        background: theme.colors['light-green-30'],
        color: theme.colors['black'],
        '& > svg': { color: theme.colors['dark-green'] },
      },
      neutral: {
        color: theme.colors['black'],
        backgroundColor: theme.colors['grey-10'],
        '& > svg': { color: theme.colors['dark-blue'] },
      },
      warning: {
        color: theme.colors['dark-yellow'],
        backgroundColor: theme.colors['light-yellow-60'],
        '& > svg': { color: theme.colors['dark-yellow-80'] },
      },
      negative: {
        color: theme.colors['dark-red'],
        backgroundColor: theme.colors['light-red-20'],
      },
      ghost: {
        boxShadow: `inset 0px 0px 0px 1px ${theme.colors['grey-15']}`,
        color: theme.colors['grey-60'],
        '& > svg': { color: theme.colors['grey-60'] },
      },
    },
  },
  defaultVariants: {
    size: 'medium',
  },
})

Chip.displayName = 'Chip'
