import { styled } from '../stitches'
import { margin, padding } from '../variants'

export interface BoxProps extends React.ComponentProps<typeof Box> {}

export const Box = styled('div', {
  variants: {
    ...margin,
    ...padding,
  },
})

Box.displayName = 'Box'
